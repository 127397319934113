import React, { useState, useEffect } from "react";
import "./seller.css";
import axios from "axios";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
function BestSeller() {
  const [seller, setSeller] = useState([]);

  const handleSeller = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/product/getProducts?is_featured=yes&status=Active`
      );
      // console.log("Best Seller Fetched");

      setSeller(response.data.data.data);
    } catch (error) {
      console.error("Error fetching best seller", error);
    }
  };

  useEffect(() => {
    handleSeller();
  }, []);

  return (
    <div className="my-1">
      <div className="flex justify-center w-full">
        <h2 className="text-3xl font-bold" style={{ color: "rgb(162, 7, 141)" }}>
          Best Seller
        </h2>
      </div>
      {/* <div className="container mx-auto mt-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {seller.map((data) => (
            <div key={data.id} className="max-w-sm mx-auto ">
              <div className="flex h-full  p-2 flex-col " style={{width:"320px"}}>
                <a href={`/product/${data?.slug}`}>
                  <img
                    src={data?.thumbnail_image}
                    alt="Product_image"
                    title="img"
                    className="mx-auto rounded-xl"
                    style={{ width: "200px", height: "200px" }}
                  />
                  <h2 className="text-l font-normal pt-1 text-center overflow-hidden line-clamp-1  px-5" >
                    {data?.product_name}
                  </h2>
                  <h3 className="text-l font-normal pt-1 text-center" style={{ color: "rgb(162, 7, 141)", fontWeight:"400" }}>
                    Price - Rs {data?.product_sell_price}
                  </h3>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div> */}

        <div className="mt-5 mx-4 md:mx-8 lg:mx-8 xl:mx-20 gap-5 flex flex-col-reverse md:flex-row justify-between">
                    <div className="w-full mb-8 grid grid-cols-3 max-sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
                      {seller?.map((product, index) => (
                        <Link to={`/product/${product.slug}`} key={index}>
                          {/* Cards */}
                          <div className="flex flex-col bg-white p-2 shadow-md rounded-lg border">
                            <div className="cursor-pointer">
                              <img
                                src={product.thumbnail_image}
                                alt={product.product_name}
                                className=" sm:items-center border border-gray-200 object-cover rounded-lg mb-2 flex  mx-auto imagesection"
                              />
                            </div>
                            <div className="text-center">
                              <h2 className="text-md">
                                <span className="font-semibold capitalize line-clamp-1">
                                  {product.product_name}
                                </span>
                                <h2 style={{ color: "rgb(162, 7, 141)" }}>
                                
                                  Price - <span className="strikethrough">₹{product?.product_price}</span> ₹{product.product_sell_price}
                                </h2>
                              </h2>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
    </div>
  );
}

export default BestSeller;
