import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './style.css';

function Certificate({ theme }) {
  const [certificateData, setCertificateData] = useState([]);

  const handleCertificate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getAllCertificates`
      );

      if (response.data.result === 1) {
        setCertificateData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching certificate', error);
    }
  };

  useEffect(() => {
    handleCertificate();
  }, []);

  return (
    <div className="skills">
      <div className="skillsHeader">
        <h1 className="text-3xl font-bold mb-2 mt-1">Certified By</h1>
      </div>
      <div className="skillsContainer">
        {certificateData?.map((certificate) => (
          <div className="skill--box" key={certificate?.id}>
            <img
              src={certificate?.certificate_image}
              alt={certificate?.name}
              title={certificate?.name}
              className="imgSlide"
            />
            <h3>{certificate?.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Certificate;
