import React, { forwardRef, useRef, useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./header.css";
import { IoBagOutline } from "react-icons/io5";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { ProfileMenu } from "../UserProfile/Profilmenu";
import { HiUserCircle } from "react-icons/hi";
import { FaRegUserCircle } from "react-icons/fa";
import { getHeaderData, getLogo } from "../../services/HeaderService";
import { useQuery } from "@tanstack/react-query";
import { getCategories } from "../../services/productService";
import { useGetCarts } from "../Hooks/useCartsService";
import { ChevronDown, ChevronUp, Search, X } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import SearchContent from "./SearchBar";
import { getToken } from "../../utils/getToken";
import Preloader from "../../Preloader";
// import SearchContent from "./SearchContent";

const CartCount = ({ count }) => {
  if (!count) return null;

  return (
    <div
      className="absolute inline-flex items-center justify-center w-5 h-5  font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 "
      style={{ fontSize: "10px" }}
    >
      {count}
    </div>
  );
};

const SearchBar = forwardRef((props, ref) => {
  const { onChange, toggleSearch } = props;

  return (
    <div
      ref={ref}
      className="  flex items-center justify-center py-4 ps-0 lg:ps-4 xl:ps-4 w-full"
    >
      <div className=" flex items-center gap-3 max-w-5xl mx-auto w-full me-5 ms-10">
        <input
          type="text"
          className="px-2 p-1 border border-slate-700 rounded-full w-full"
          style={{ backgroundColor: "white", width: "100%" }}
          placeholder="Search our store"
          autoFocus={true}
          onChange={onChange}
        />
        <X onClick={toggleSearch} className="absolute right-0 text-white" />
      </div>
    </div>
  );
});

const HeaderData = ({ isVisible }) => {
  const [searchBarMobileShow, setSearchBarMobileShow] = useState(false);
  const searchBarMobileRef = useRef(null);
  const [searchContent, setSearchContent] = useState(null);
  const [searchBarShow, setSearchBarShow] = useState(false);
  const [click, setClick] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const searchBarRef = useRef(null);
  const toggleSearch = () => {
    setSearchBarShow((old) => !old);
    setSearchContent(false);
  };

  const toggleMobileSearch = () => {
    setSearchBarMobileShow((old) => !old);
  };

  const onChange = (e) => {
    // console.log("search input", e);
    setSearchContent(e.target.value);
    // console.log(searchContent);
  };
  const localStore = getToken();

  const { data, status, error } = useQuery({
    queryKey: ["header"],
    queryFn: getHeaderData,
  });
  // console.log("category", data);
  const { data: logoData } = useQuery({
    queryKey: ["logo"],
    queryFn: getLogo,
  });
  const { data: catagoryData } = useQuery({
    queryKey: ["catagory"],
    queryFn: getCategories,
  });
  // console.log("Header rendered");

  const catData = data?.filter((item) => {
    const a = catagoryData?.filter(
      (i) => i.parent_category_id == parseInt(item.category_id)
    );
    if (item.is_child == "yes") {
      item.children = a;
    } else {
      item.children = [];
    }
    return item;
  });
  const { data: cartData } = useGetCarts();

  return (
    <div>
      <nav className="flexSB ">
        <div className="flex items-center ">
          <div className="start ">
            <div className="button">
              <Link to="/">
                <img
                  src={logoData?.company_logo}
                  alt="error"
                  className="w-20 sm:w-20 md:w-20 logoHeader"
                />
              </Link>
            </div>
          </div>
          <div className="flex items-center desktop-search ">
            <div className="">
              {searchBarShow && (
                <div
                  className="flex items-center top-0 left-0 absolute bg-slate-200 p-3 z-20 w-full  ps-5"
                  style={{ backgroundColor: "rgb(0 0 0 / 87%" }}
                >
                  <Search onClick={toggleSearch} className="text-white" />
                  <SearchBar
                    ref={searchBarRef}
                    onChange={onChange}
                    toggleSearch={toggleSearch}
                  />
                </div>
              )}
              {searchContent && (
                <div
                  className="absolute z-20 top-20 left-10 right-10 text-black p-3 border overflow-y-auto rounded "
                  style={{
                    backgroundColor: "rgb(255 231 179)",
                    height: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <SearchContent searchContent={searchContent} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full">
          <ul
            className={
              click ? "mobile-nav" : "flexSB justify-center w-4/6 pl-2 "
            }
          >
            {data?.map((item) => (
              <>
                {item.link === null ? (
                  // <div className={`hover:text-orange-600 flex items-center font-medium cursor-pointer`}>{item.title} </div>
                  <></>
                ) : (
                  <li
                    onMouseLeave={() => setIsDropdownOpen(false)}
                    className="listitemmobile flex "
                  >
                    <Link
                      to={`${item.link}`}
                      className={`hover:text-orange-600 flex items-center font-bold uppercase`}
                      onClick={() => {
                        setClick(false);
                        setIsDropdownOpen(false);
                      }}
                    >
                      <div
                        style={{
                          color: isVisible && window.innerWidth > 1000 ? '#FFF' : '#000',
                          textWrap: "nowrap",
                        }}
                      >
                        {item.title}{" "}
                      </div>
                    </Link>
                  </li>
                )}

                {item.is_child === "yes" && item.type == "category" ?
                  (window.innerWidth > 1000 ?
                    (
                      <li
                        onMouseLeave={() => setIsDropdownOpen(false)}
                        className="listitemmobile flex "
                      >
                        <div
                          className="arrow-down-header relative flex hover:text-orange-600"
                          style={{ color: isVisible && window.innerWidth > 1000 ? '#FFF' : '#000' }}
                          onClick={() => {
                            setClick(false);
                            setIsDropdownOpen(false);
                          }}
                        >
                          <div
                            className={`hover:text-orange-600 flex items-center  cursor-pointer font-bold uppercase `}
                            onMouseEnter={() => setIsDropdownOpen(true)}
                          >
                            {item.title}{" "}
                          </div>
                          {isDropdownOpen ? (
                            <ChevronUp className="text-sm mt-1 " />
                          ) : (
                            <ChevronDown
                              className="text-sm mt-1"
                              onMouseEnter={() => setIsDropdownOpen(true)}
                            />
                          )}
                          {isDropdownOpen && (
                            <div className="absolute top-7 left-0 bg-white  shadow-lg z-1 rounded overflow-hidden">
                              {catagoryData?.map((product, index) => (
                                <div
                                  className="dropdown-content space-y-2 p-2 cursor-pointer whitespace-nowrap border-b px-10 "
                                  style={{ display: "grid" }}
                                >
                                  <NavLink
                                    to={`/sweets?category_id=${product.parent_category_id}`}
                                    className="text-sm text-base hover:text-orange-600 "
                                  >
                                    {product.category_name}
                                  </NavLink>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </li>
                    )
                    :
                    (
                      catagoryData?.map((product, index) => {
                        return (
                          <li
                            onMouseLeave={() => setIsDropdownOpen(false)}
                            className="listitemmobile flex"
                          >
                            <NavLink
                              to={`sweets?category_id=${product.parent_category_id}`}
                              className={`hover:text-orange-600 flex items-center font-bold uppercase`}
                              onClick={() => {
                                setClick(false);
                                setIsDropdownOpen(false);
                              }}
                              style={{
                                textWrap: "nowrap",
                                color: isVisible && window.innerWidth > 1000 ? '#FFF' : '#000'
                              }}
                            >
                              {product.category_name}
                            </NavLink>
                          </li>
                        )
                      })
                    ))
                  : item.is_child === "no" && item.type == "category" ? (

                    <li
                      onMouseLeave={() => setIsDropdownOpen(false)}
                      className="listitemmobile flex "
                    >
                      <Link
                        to={`sweets?category_id=${item.category_id}`}
                        className={`hover:text-orange-600 flex items-center font-bold uppercase`}
                        onClick={() => {
                          setClick(false);
                          setIsDropdownOpen(false);
                        }}
                      >
                        <div>{item.title} </div>
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
              </>
            ))}
            <li
              onMouseLeave={() => setIsDropdownOpen(false)}
              className="listitemmobile flex"
            >
              <Link
                to="/makingprocess"
                className={`hover:text-orange-600 flex items-center font-bold uppercase`}
                onClick={() => {
                  setClick(false);
                  setIsDropdownOpen(false);
                }}
                style={{
                  textWrap: "nowrap",
                  color: isVisible && window.innerWidth > 1000 ? '#FFF' : '#000'
                }}
              >
                Making Process
              </Link>
            </li>
            <li
              onMouseLeave={() => setIsDropdownOpen(false)}
              className="listitemmobile flex"
            >
              <Link
                to="/contact"
                className={`hover:text-orange-600 flex items-center font-bold uppercase`}
                onClick={() => {
                  setClick(false);
                  setIsDropdownOpen(false);
                }}
                style={{
                  textWrap: "nowrap",
                  color: isVisible && window.innerWidth > 1000 ? '#FFF' : '#000'
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>

          <ul
            className={click ? "mobile-nav" : "flexSB justify-center ml-auto"}
            style={{ width: "240px" }}
          >
            <div className="flex items-center mobile-search"></div>

            <li
              className="relative right-menu-des border border-black-600 bg-white p-2"
              style={{ borderRadius: "50px" }}
            >
              <div className="flex items-center desktop-search">
                <Search onClick={toggleSearch} size={22} />
              </div>
            </li>
            <li
              className=" rounded-full  cursor-pointer right-menu-des border border-black-600 bg-white p-1"
              style={{ borderRadius: "50px" }}
            >
              {localStore ? (
                <ProfileMenu size={28} />
              ) : (
                <Link to="/sign-up">
                  <FaRegUserCircle
                    size={28}
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </Link>
              )}
            </li>
            <li
              className="relative right-menu-des border border-black-600 bg-white p-2"
              style={{ borderRadius: "50px" }}
            >
              <Link to={localStore ? "/add-to-cart" : "/sign-up"}>
                <HiOutlineShoppingBag
                  size={25}
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <CartCount
                  count={Object.entries(cartData?.data.data || {}).length}
                />
              </Link>
            </li>
          </ul>
        </div>
        <ul className="right-menu-mb mr-10 mt-5" >
          <li className="relative" style={{ marginInline: "8px" }}>
            <Search onClick={toggleSearch} style={{ fontWeight: "bold" }} />
          </li>
          <li
            className=" rounded-full  cursor-pointer py-1 px-2"
            style={{ width: "45px", marginInline: "8px" }}
          >
            {localStore ? (
              <ProfileMenu />
            ) : (
              <Link to="/sign-up">
                <FaRegUserCircle
                  size={30}
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Link>
            )}
          </li>
          <li className="relative">
            <Link to={localStore ? "/add-to-cart" : "/login"}>
              <IoBagOutline
                size={25}
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <CartCount
                count={Object.entries(cartData?.data.data || {}).length}
              />
            </Link>
          </li>
        </ul>
        <button className="toggle" onClick={() => setClick(!click)}>
          {click ? (
            <FontAwesomeIcon icon={faTimes} className="text-black" />
          ) : (
            <FontAwesomeIcon icon={faBars} className="text-black" />
          )}
        </button>
      </nav>
    </div>
  );
};


const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  const listenToScroll = () => {
    let heightToHidden = 50;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <div className="fixed top-0 right-0 left-0 z-10">

      {isVisible ? (
        <header className="headernew" >
          <HeaderData isVisible={isVisible} />
        </header>
      ) : (
        <header className="relative">
          <HeaderData isVisible={isVisible} />
        </header>
      )}
    </div>
  );
};

export default Header;
