// Footer.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getFooterData } from "../../services/FooterService";
import { useQuery } from "@tanstack/react-query";
import { getLogo } from "../../services/HeaderService";
import * as SocialIcons from "./Social";
import Info from "../Info/Info";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer() {
  const [email, setEmail] = useState("");
  const { data: logoData } = useQuery({
    queryKey: ["logo"],
    queryFn: getLogo,
  });
  const { data, status } = useQuery({
    queryKey: ["footer"],
    queryFn: getFooterData,
  });

  const footerLinks = data?.filter((item) => item.type === "footerlink");

  const handleSubscribe = async() => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/addSubscriber`,{
          email: email,
        }
      )
      // console.log('Subscription successful', response);
      toast.success("Subscription Added")
    } catch (error) {
      console.log("Failed to Subscribe", error);
      toast.error("Failed to Subscribe");
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault(); 
    handleSubscribe(); 
  };
  return (
    <>
      <Info />
      {/* <div className="">
        <div className="bg-[#FBC44B]" style={{ fontFamily: "Roboto" }}>
          <div className="flex flex-col md:flex-row justify-between items-center px-4 lg:px-24 xl:px-24 py-4 md:py-24 gap-0 md:gap-5 lg:gap-5 xl:gap-5">
            {footerLinks?.map((item) => (
              <div className="flex flex-col mb-6 md:mb-0 " key={item.id}>
                <h4 className="text-black font-semibold text-center md:text-left text-2xl ">
                  {item.title}
                </h4>
                <div className="mt-2 md:mt-5 flex flex-col space-y-2 text-center md:text-left text-lg">
                  {item?.linksdata?.map((data) => (
                    <Link key={data.id} to={data.link_url}>
                      {data.link_name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
            <div className="flex flex-col justify-center items-center">
              <img
                src={logoData?.company_logo}
                alt="error"
                style={{ width: "160px", height: "90px" }}
                className="mt-4"
              />
              <div className="flex space-x-2 mt-2 justify-center items-center ">
                <Link to={logoData?.social_instagram_link}>
                  <SocialIcons.InstagramIcon width="30px" height="30px" />
                </Link>
                <Link to={logoData?.social_linkedin_link}>
                  <SocialIcons.LinkedinIcon width="30px" height="30px" />
                </Link>
                <Link to={logoData?.social_fb_link}>
                  <SocialIcons.FacebookIcon width="28px" height="28px" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <div className="flex flex-col lg:flex-row md:flex-row w-full my-5 ">
          <div className="flex w-full lg:w-1/4 md:w-1/4 justify-center py-2">
            <div className="flex flex-col justify-center items-center">
              <img
                src={logoData?.company_logo}
                alt="error"
                style={{ width: "160px", height: "90px" }}
                className="mt-4"
              />
              <div className="flex space-x-2 mt-2 justify-center items-center">
                <Link to={logoData?.social_fb_link}>
                  <SocialIcons.FacebookIcon width="28px" height="28px" />
                </Link>
                <Link to={logoData?.social_instagram_link}>
                  <SocialIcons.InstagramIcon width="30px" height="30px" />
                </Link>
                <Link to={logoData?.social_linkedin_link}>
                  <SocialIcons.LinkedinIcon width="30px" height="30px" />
                </Link>
              </div>
              <form onSubmit={handleSubmit}>
              <p className="mt-2" style={{color: "rgb(162, 7, 141)", fontWeight:"600"}}>Get the scoop on our best sales!</p>
              <div className="flex flex-row py-2"><input className="px-2 flex" placeholder="Enter Your Email" type="email" required onChange={(e)=> setEmail(e.target.value)} style={{borderBottom :"1px solid black", fontSize:"12px",outline: "none"}}/>
              <button className="text-white p-1 rounded ml-1" style={{fontSize:"13px",background: "rgb(162, 7, 141)"}} type="submit">Subscribe</button>
              </div>
              </form>
            </div>
          </div>
          {footerLinks?.map((item) => (
          <div className="flex w-full lg:w-1/4 md:w-1/4 justify-center py-2">
            <div className="flex flex-col mb-6 md:mb-0 " key={item.id}>
              <h4
                className="text-black font-semibold text-center md:text-left text-2xl "
                style={{ color: "rgb(162,7,141)" }}
              >
                {item.title}
              </h4>
              <div className="mt-2 md:mt-5 flex flex-col space-y-2 text-center md:text-left text-lg">
              {item?.linksdata?.map((data) => (
            
                <Link key={data.id} to={data.link_url}> {data.link_name}</Link>
              ))}
              </div>
            </div>
          </div>
          ))}

        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Footer;
