import React from "react";

const Second = ({ perksData }) => {
  return (
    <div className="my-4">
      <div className="items-container">
        {perksData?.map((item) => (
          <div
            key={item?.perk_id}
            className="image-container text-center items-center justify-center"
          >
            <img
              src={item?.perk_image}
              alt="perks"
              title="perks"
              className="perk-image"
            />
            {item.title !== "." &&(
            <h2 className="text-sm font-semibold">{item?.title}</h2>)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Second;
