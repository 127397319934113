import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { SiWhatsapp } from "react-icons/si";

function WhatsAppButton() {
  const whatsappLink = "https://wa.me/919427265661";

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-2 right-3 bg-green-500 rounded-full shadow-lg cursor-pointer"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '35px',
        // height: '35px',
        zIndex:'9999'
      }}
    >
      <SiWhatsapp size={35} color="white" />
      {/* <FaWhatsapp size={28} color="white" /> */}
    </a>
  );
}

export default WhatsAppButton;
